import React, { useRef, useEffect, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Info, Minimize2, Maximize2 } from 'react-feather';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { bbox, lineString } from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

function enterFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
}

function exitFullscreen() {
  document.exitFullscreen();
}

const handleCaptures = ({ mapRef, captures }) => {
  if (!mapRef.current._loaded) return;

  const geoJSON = {
    type: 'FeatureCollection',
    features: captures.map(capture => ({
      type: 'Feature',
      properties: {
        id: capture._id,
        thermal: capture.thermal,
        orientation: capture.point.properties.flightOrientation.yaw,
      },
      geometry: {
        type: 'Point',
        coordinates: capture.point.coordinates,
      },
    })),
  };

  if (captures.length > 0) {
    mapRef.current.fitBounds(bbox(lineString(
      captures.map(capture => capture.point.coordinates)
    )), { padding: 200, maxZoom: 16 });
  }

  mapRef.current.getSource('heatmap-source').setData(geoJSON);
};

const handleTilesets = ({ mapRef, tilesetsRef, tilesets }) => {
  if (!mapRef.current._loaded) return;

  if (tilesetsRef.current.length) {
    tilesetsRef.current.map(tileset => {
      mapRef.current.removeLayer(tileset);
      mapRef.current.removeSource(tileset);
    });

    tilesetsRef.current = [];
  }

  if (tilesets.length) {
    tilesets.map(tileset => {
      mapRef.current.addSource(tileset, {
        type: 'raster',
        url: `mapbox://${tileset}`,
      });

      mapRef.current.addLayer({
        id: tileset,
        source: tileset,
        type: 'raster',
        paint: {
          'raster-opacity': 1,
          'raster-saturation': 0.25,
          'raster-fade-duration': 100,
        },
      });
    });

    tilesetsRef.current = tilesets;
  }
};

const GlobalStyle = createGlobalStyle`
  .mapboxgl-ctrl-geolocate {
    display: none !important;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Controls = styled.div`
  position: absolute;
  top: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ControlsRight = styled(Controls)`
  right: 0;
`;

const ControlsLeft = styled(Controls)`
  left: 0;
`;

const Button = styled.div`
  display: flex;
  padding: 8px;
  background: white;
  border-radius: 5px;
  cursor: pointer;

  ${({ status }) => status === 'loading' && css`
    & > * {
      animation: loading 500ms infinite;
    }
  `};

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(90deg);
    }
  }
`;

const ImagesWrapper = styled.div`
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  padding: ${({ active }) => (active ? '40px' : '20px')};;
  justify-content: ${({ active }) => (active ? 'center' : 'flex-end')};;
  align-items: ${({ active }) => (active ? 'center' : 'flex-end')};;
  pointer-events: ${({ active }) => (active ? 'initial' : 'none')};
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: #111;
    transition: opacity 500ms;
    opacity: ${({ active }) => (active ? 0.4 : 0)};
    z-index: -1;
  }
`;

const Thumbnail = styled(motion.img)`
  width: 30%;
  max-width: 300px;
  border-radius: 12px;
  pointer-events: none;
  align-self: flex-end;
`;

const Modal = styled(motion.img)`
  width: 100%;
  max-width: 1200px;
  border-radius: 24px;
  align-self: center;

  @media (min-aspect-ratio: 4/3) {
    width: auto;
    max-width: auto;
    height: 100%;
    max-height: 800px;
  }
`;

function Dashboard({ value, ...props }) {
  const containerRef = useRef();

  const [showLabels, setShowLabels] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);

  const [hoveredImage, setHoveredImage] = useState(null);
  const [activeImage, setActiveImage] = useState(null);

  mapboxgl.accessToken = props.accessToken;

  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const tilesetsRef = useRef([]);

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/thehive-rs/cl5ad6srb000p14nchwpcsfds',
      pitch: 0,
      bearing: 0,
      cooperativeGestures: true,
      attributionControl: false,
      projection: 'globe',
      center: props.center,
      zoom: props.zoom,
    });

    return () => mapRef.current.remove();
  }, []);

  useEffect(() => {
    mapRef.current.on('load', () => {
      if (mapRef?.current?.getSource('heatmap-source')) return;

      mapRef.current.addSource('heatmap-source', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      mapRef.current.addLayer({
        id: 'heatmap',
        type: 'heatmap',
        source: 'heatmap-source',
        paint: {
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0, 'rgba(0, 0, 0, 0)',
            1, '#F01F37',
          ],
          'heatmap-weight': [
            'interpolate',
            ['linear'],
            ['get', 'thermal'],
            0, 0,
            1, 10,
          ],
          'heatmap-opacity': 0.9,
          'heatmap-radius': [
            'interpolate',
            ['cubic-bezier', 1, 0, 1, 1],
            ['zoom'],
            8, 0,
            22, [
              'interpolate',
              ['linear'],
              ['get', 'thermal'],
              0.1, 500,
              1, 1500,
            ],
          ],
        },
      });

      mapRef.current.addLayer({
        id: 'heatmap-points',
        type: 'circle',
        source: 'heatmap-source',
        paint: {
          'circle-color': 'transparent',
          'circle-radius': [
            'interpolate',
            ['cubic-bezier', 1, 0, 1, 1],
            ['zoom'],
            8, 0,
            22, [
              'interpolate',
              ['linear'],
              ['get', 'thermal'],
              0, 0,
              0.1, 200,
              1, 1000,
            ],
          ],
        },
      });

      handleCaptures({ mapRef, captures: value?.captures || [] });
      handleTilesets({ mapRef, tilesetsRef, tilesets: value?.tilesets || [] });
    });

    return () => {
      mapRef.current.off('load');
    };
  }, [value]);

  // UPDATE ON VALUE CHANGE
  useEffect(() => {
    handleCaptures({ mapRef, captures: value?.captures || [] });
  }, [value?.captures]);

  // UPDATE TILESEETS ON VALUE CHANGES
  useEffect(() => {
    handleTilesets({ mapRef, tilesetsRef, tilesets: value?.tilesets || [] });
  }, [value?.tilesets]);

  // HANDLE HOVER
  useEffect(() => {
    mapRef.current.on('mousemove', 'heatmap-points', event => {
      if (event.features.length === 0) return;
      setHoveredImage(value.captures
        .find(i => i._id === event.features[0].properties.id)
      );
    });

    return () => mapRef.current.off('mousemove');
  }, [value.captures]);

  // RESET HOVERED IMAGE
  useEffect(() => {
    mapRef.current.on('mouseleave', 'heatmap-points', () => {
      setHoveredImage(null);
    });

    return () => mapRef.current.off('mouseleave');
  }, []);

  // HANDLE CLICK
  useEffect(() => {
    mapRef.current.on('click', 'heatmap-points', event => {
      if (event.features.length === 0) return;

      setActiveImage(value.captures
        .find(i => i._id === event.features[0].properties.id)
      );

      mapRef.current.flyTo({
        center: event.features[0].geometry.coordinates,
        zoom: 18,
        bearing: event.features[0].properties.orientation,
        pitch: 0,
        duration: 1000,
        essential: true,
      });
    });

    return () => mapRef.current.off('click');
  }, [value.captures]);

  const handleClose = () => {
    setActiveImage(null);
    mapRef.current.flyTo({
      zoom: 17,
      duration: 500,
      essential: true,
    });
  };

  // FULLSCREEN CONTROL
  const handleFullscreen = () => {
    if (fullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen(containerRef.current);
    }
    setFullscreen(!fullscreen);
  };

  // LABELS CONTROL
  const handleShowLables = () => {
    if (showLabels) {
      mapRef.current.style.stylesheet.layers.forEach((layer) => {
        if (layer.type === 'symbol') {
          mapRef.current.setLayoutProperty(layer.id, 'visibility', 'none');
        }
      });
    } else {
      mapRef.current.style.stylesheet.layers.forEach((layer) => {
        if (layer.type === 'symbol') {
          mapRef.current.setLayoutProperty(layer.id, 'visibility', 'visible');
        }
      });
    }
    setShowLabels(!showLabels);
  };

  return (
    <Container ref={containerRef}>
      <GlobalStyle />
      <div
        ref={mapContainerRef}
        className="map-container"
        style={{ width: '100%', height: '100%' }}
      />
      <ControlsLeft>
        <Button onClick={handleShowLables}>
          <Info size={18} color={showLabels ? '#333' : '#aaa'} />
        </Button>
      </ControlsLeft>
      <ControlsRight>
        <Button onClick={handleFullscreen}>
          {fullscreen ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
        </Button>
      </ControlsRight>
        <ImagesWrapper active={activeImage} onClick={handleClose}>
          <AnimateSharedLayout>
            {/* <AnimatePresence> */}
              {hoveredImage?.image?.src && (
                <Thumbnail
                  key="thumbnail"
                  layoutId="image"
                  src={hoveredImage?.image?.src}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              )}
              {activeImage?.image?.src && (
                <Modal
                  key="modal"
                  layoutId="image"
                  src={activeImage?.image?.src}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              )}
            {/* </AnimatePresence> */}
          </AnimateSharedLayout>
        </ImagesWrapper>
    </Container>
  );
}

Dashboard.defaultProps = {
  zoom: 10,
  center: [18.600745827365422, 42.45309480289012],
};

export default Dashboard;
