import React from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';
import mapboxgl from 'mapbox-gl';

import updateRoute from './updateRoute';
import updatePath from './updatePath';

import Pin from '../Pin';

function createMarker({
  home,
  coordinates,
  mapRef,
  propsRef,
  markersRef,
  dragendTimeoutRef,
}) {
  if (!mapRef.current) return;

  const MARKER_ID = uuid();

  const markerNode = document.createElement('marker');

  const marker = new mapboxgl.Marker(markerNode, {
    draggable: true,
    anchor: 'bottom',
  })
    .setLngLat(coordinates)
    .addTo(mapRef.current);

  ReactDOM.render(
    (
      <>
        <Pin
          id={MARKER_ID}
          marker={marker}
          markersRef={markersRef}
          home={home}
          onClick={(e) => {
            e.stopPropagation();

            if (Date.now() - dragendTimeoutRef.current > 100) {
              if (propsRef.current.onMarkerClick) {
                propsRef.current.onMarkerClick({ marker, id: MARKER_ID });
              } else {
                  if ((home && markersRef.current.length > 1)) return;

                  marker.remove();
                  markersRef.current = markersRef.current.filter(
                    m => m.id !== MARKER_ID
                  );

                  updatePath({ mapRef, propsRef, markersRef });
                  updateRoute({ mapRef, propsRef, markersRef });
                }
            }
          }}
        />
        {/* <div onClick={(event) => {
          event.stopPropagation();
          markersRef.current = markersRef.current.map(m => (m.id === MARKER_ID
            ? { ...m, elevation: m.elevation + 100 } : m
          ));

          updatePath(markersRef.current);
          updateRoute(markersRef.current);
        }}
        >
          ALTITUDE UP
        </div> */}
      </>
    ),
    markerNode,
  );

  markersRef.current.push({
    id: MARKER_ID,
    marker,
    coordinates,
    // elevation: propsRef?.current.missionAltitude,
    // pitch: propsRef?.current.missionPitch,
  });

  updatePath({ mapRef, propsRef, markersRef });
  updateRoute({ mapRef, propsRef, markersRef });

  marker.on('drag', (e) => {
    const lngLat = e.target.getLngLat();

    markersRef.current = markersRef.current.map(m => (m.id === MARKER_ID
      ? { ...m, coordinates: [lngLat.lng, lngLat.lat] } : m
    ));

    updatePath({ mapRef, propsRef, markersRef });

    mapRef.current.setPaintProperty(
      'points',
      'circle-opacity-transition',
      { duration: 100 }
    );
    mapRef.current.setPaintProperty(
      'points',
      'circle-opacity',
    0);
  });

  marker.on('dragend', () => {
    dragendTimeoutRef.current = Date.now();
    updateRoute({ mapRef, propsRef, markersRef });

    mapRef.current.setPaintProperty(
      'points',
      'circle-opacity-transition',
      { duration: 500 }
    );
    mapRef.current.setPaintProperty(
      'points',
      'circle-opacity',
      1
    );
  });
}

export default createMarker;
