import React from 'react';
import styled, { css } from 'styled-components';
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Area,
  Scatter,
} from 'recharts';

const Container = styled.div`
  position: relative;
  padding: 20px 0 0;
`;

const Stat = styled.div`
  position: absolute;
  ${({ position }) => position.map(pos => css`${pos}: 12px;`)}
  font-family: inherit;  
  color: ${({ color }) => color};
`;

function ElevationGraph({
  terrain,
  route,
  bgGradient,
  bgColor,
  lineColor,
  textColor,
  pointColor,
  pointStroke,
  aspectRatio,
}) {
  const adjustedRoute = route.map(point => ({
    distance: point.distance,
    elevation: point.elevation.absolute,
  }));

  const userRoute = route.filter(({ type }) => type === 'user').map(point => ({
    distance: point.distance,
    elevation: point.elevation.absolute,
  }));

  const routeElevation = Math.ceil(
    Math.max(...route.map(r => r.elevation.absolute))
  );

  const { distance } = terrain.at(-1);

  return (
    <Container>
      <ResponsiveContainer width="100%" aspect={aspectRatio}>
        <ComposedChart data={terrain} margin={{ top: 10 }}>

          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="50%"
                stopColor={bgColor}
                stopOpacity={0.9}
              />
              <stop
                offset="100%"
                stopColor={bgColor}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>

          <XAxis
            xAxisId={2}
            dataKey="distance"
            type="number"
            domain={[0, distance]}
            hide="true"
          />

          <XAxis
            xAxisId={1}
            dataKey="distance"
            type="number"
            domain={[0, distance]}
            hide="true"
          />

          <YAxis
            dataKey="elevation"
            type="number"
            domain={[0, routeElevation]}
            hide="true"
          />

          <Area
            xAxisId={1}
            dataKey="elevation"
            stroke="transparent"
            fill={bgGradient ? 'url(#gradient)' : bgColor}
            fillOpacity={0.85}
          />

          <Area
            xAxisId={1}
            data={adjustedRoute}
            dataKey="elevation"
            stroke={lineColor}
            strokeWidth={1.5}
            fill="transparent"
          />

          <Scatter
            xAxisId={1}
            data={userRoute}
            dataKey="elevation"
            fill={pointColor}
            strokeWidth={2.5}
            stroke={pointStroke}
          />

        </ComposedChart>
      </ResponsiveContainer>

      <Stat
        color={textColor}
        position={['top', 'left']}
      >
        {`${routeElevation}m`}
      </Stat>

      <Stat
        color={textColor}
        position={['bottom', 'left']}
      >
        0m
      </Stat>

      <Stat
        color={textColor}
        position={['bottom', 'right']}
      >
        {`${distance}m`}
      </Stat>
    </Container>
  );
}

ElevationGraph.defaultProps = {
  terrain: [],
  route: [],
  bgGradient: false,
  bgColor: '#f01f37',
  lineColor: '#f01f37',
  textColor: '#333',
  pointColor: '#f01f37',
  pointStroke: '#fff',
  aspectRatio: 6,
};

export default ElevationGraph;
