import { along, length } from '@turf/turf';
import { lineString } from '@turf/helpers';

import getDroneInfo from './getDroneInfo';
import simplifyPath from './simplifyPath';

function calculateRoute({
  mapRef,
  markersRef,
  propsRef,
 }) {
  const {
    missionAltitude,
    autoFollowTerrain,
    useTerrainElevation,
    addedHomeAltitude,
    elevationPrecision,
    elevationSimplificationFactor,
    maxWaypointDistance,
    missionSpeed,
    forwardOverlap,
    sideOverlap,
  } = propsRef.current;

  const {
    maxAscentSpeed,
    maxDescentSpeed,
    photoCoverageHeight,
    battery,
  } = getDroneInfo({ propsRef });

  // Define all points by using user points and home point
  const userWaypoints = [
    ...markersRef.current,
    markersRef.current[0],
  ];

  // Query home elevation
  const homeElevation = mapRef.current.queryTerrainElevation(
    markersRef.current[0].coordinates, { exaggerated: false }
  );

  // Create user generated route
  const userRoute = userWaypoints.map((point, index) => {
    const distance = Math.round(length(lineString([
      ...userWaypoints.slice(0, index + 1).map(item => item.coordinates),
      point.coordinates,
    ]), { units: 'meters' }));

    // Get terrain elevation at point
    const terrainElevation = mapRef.current
      .queryTerrainElevation(point.coordinates, { exaggerated: false });

    // Dismiss user point elevation if autoFollowTerrain is enabled
    // as otherwise elevation calculations will be wrong (or difficult)
    const pointElevation = autoFollowTerrain
      ? missionAltitude
      : point.elevation ? point.elevation : missionAltitude;

    return {
      coordinates: point.coordinates,
      distance,
      elevation: {
        terrain: terrainElevation,
         // REAL ELEVATION
        absolute: useTerrainElevation
          ? terrainElevation + pointElevation
          : homeElevation + pointElevation,
         // DRONE READINGS
        relative: useTerrainElevation
          ? terrainElevation + pointElevation
            - (homeElevation + addedHomeAltitude)
          : pointElevation - addedHomeAltitude,
      },
      type: (index === 0 || index === userWaypoints.length - 1)
        ? 'home'
        : 'user',
    };
  });

  // Create path from all points and meaasure total distance
  const PATH = lineString(userWaypoints.map(point => point.coordinates));
  const distance = Math.round(length(PATH, { units: 'meters' }));

  // Loop over mission path and calculate elevation for each point
  const terrain = [
    ...Array(Math.round(distance / elevationPrecision) + 1).keys(),
  ].map((lastWaypoint, index) => {
    // Create points along the path
    const alongPath = along(
      PATH, elevationPrecision * index, { units: 'meters' }
    ).geometry.coordinates;

    // Query elevation for each point
    const elevation = mapRef.current.queryTerrainElevation(
      alongPath, { exaggerated: false }
    );

    return {
      coordinates: alongPath,
      distance: Math.round(
        distance / (distance / elevationPrecision) * index
      ),
      elevation,
    };
  });

  // Create route that follows terrain
  const terrainRoute = simplifyPath(
    terrain.map(p => ({ x: p.distance, y: p.elevation })),
    elevationSimplificationFactor / 20 // index to get aproximate meters
  ).map(s => ({
    ...terrain.find(p => p.distance === s.x),
    elevation: {
      terrain: s.y,
      absolute: s.y + missionAltitude,
      relative: s.y + missionAltitude - (homeElevation + addedHomeAltitude),
    },
    type: 'terrain',
  }));

  // Combine user route with terrain route
  const combinedRoute = [
    ...terrainRoute
      // Remove first and last item from terrain route as they are duplicates of user route
      .filter((_, i) => i !== 0)
      .filter((_, i) => i !== terrainRoute.length - 2), // TODO: hack, check this
    ...userRoute,
    // TODO: also remove terrain points closer than terrain precision to the user point
  ].sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));

  // const fixedCombinedRoute = combinedRoute.map((point, index) => {
  //   const nextPoint = combinedRoute[index + 1];
  //   const distanceDiff = (nextPoint.distance - point.distance)
  //     || propsRef.current.elevationPrecision;
  //   const elevationDiff = Math.abs(
  //     nextPoint.elevation.relative - point.elevation.relative
  //   );

  //   if (distanceDiff < propsRef.current.elevationPrecision) {
  //     return {
  //       ...point,
  //       elevation: {
  //         ...point.elevation,
  //         relative: point.elevation.relative + elevationDiff,
  //       },
  //     };
  //   }

  //   return point;
  // }).filter((point, index) => {
  //   const nextPoint = combinedRoute[index + 1];
  //   const distanceDiff = nextPoint.distance - point.distance;
  //   return distanceDiff > propsRef.current.elevationPrecision;
  // });

  // Decide which route is being used based on user settings
  const route = useTerrainElevation
    ? autoFollowTerrain
      ? combinedRoute
      : userRoute
    : userRoute;

  // Make sure the mission is possible (waypoint every maxWaypointDistance)
  const possibleRoute = route.map((point, index) => {
    const nextPoint = route[index + 1];

    // Measure distance between point and next point
    const partDistance = Math.round(length(lineString([
      point.coordinates,
      nextPoint ? nextPoint.coordinates : point.coordinates,
    ]), { units: 'meters' }));

    let newPoints;
    if (partDistance > maxWaypointDistance) {
      // Add point every maxWaypointDistance meters between current point and next point
      newPoints = [
        ...Array(Math.ceil(partDistance / maxWaypointDistance)).keys(),
      ].map((_, i) => {
        const alongPath = along(lineString([
            point.coordinates,
            nextPoint ? nextPoint.coordinates : point.coordinates,
          ]), maxWaypointDistance * i, { units: 'meters' }
        ).geometry.coordinates;

        return alongPath;
      });
    }

    // Destructure new points if distance higher than maxWaypointDistance
    return partDistance > maxWaypointDistance ? [
      ...newPoints.map((p, i) => ({
          coordinates: p,
          distance: point.distance + maxWaypointDistance * i,
          elevation: {
            // Use real elevation under the point
            terrain: mapRef.current.queryTerrainElevation(
              p, { exaggerated: false }
            ),
            // Calculate absolute and relative values, relative to the current and next point
            // to ensure correct values even when not using terrain elevation
            absolute: point.elevation.absolute
              + (nextPoint.elevation.absolute - point.elevation.absolute)
              * (maxWaypointDistance * i / partDistance),
            relative: point.elevation.relative
              + (nextPoint.elevation.relative - point.elevation.relative)
              * (maxWaypointDistance * i / partDistance),
          },
          type: 'distance',
        })),
    ] : [point];
  });

  // Flatten and sort the above array
  const possibleRouteFlattened = possibleRoute
    .reduce((acc, item) => [...acc, ...item], [])
    .sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));

  // Create time and battery consumption estimates
  const estimates = possibleRouteFlattened.reduce((acc, point, i) => {
    const nextPoint = possibleRouteFlattened[i + 1];
    if (!nextPoint) { return acc; }

    // Calculate distance between point and next point
    const segmentDistance = nextPoint.distance - point.distance;
    // Calculate elevation difference between point and next point
    const elevationDifference = nextPoint.elevation.relative
      - point.elevation.relative;

    // Calculate time required to go over distance
    const distanceTime = segmentDistance / missionSpeed;

    // Calculate time required to go to elevation
    const elevationTime = (elevationDifference > 0)
      ? Math.abs(elevationDifference) / maxAscentSpeed
      : Math.abs(elevationDifference) / maxDescentSpeed;

    // Calculate battery required to go over distance
    const distanceBattery = segmentDistance * battery.distanceConsumption;

    // Calculate battery required to go to elevation
    const elevationBattery = (elevationDifference > 0)
      ? Math.abs(elevationDifference) * battery.ascentConsumption
      : Math.abs(elevationDifference) * battery.descentConsumption;

    // Return higher of the two values for each
    return {
      time: Math.round(
        // acc.time + Math.max(distanceTime, elevationTime)
        acc.time + distanceTime + elevationTime,
      ),
      battery: Math.round(
        // acc.battery + Math.max(distanceBattery, elevationBattery)
        acc.battery + distanceBattery + elevationBattery,
      ),
    };
  }, {
    // Set time required for ascent and descent
    time: (
      (missionAltitude - addedHomeAltitude) / maxAscentSpeed
      + (missionAltitude - addedHomeAltitude) / maxDescentSpeed
    ),
    // Set battery required for ascent and descent
    battery: (
      (missionAltitude - addedHomeAltitude) * battery.ascentConsumption
      + (missionAltitude - addedHomeAltitude) * battery.descentConsumption
    ),
  });

  // const minTerrainElevation = Math.round(
  //   Math.min(...terrain.map(point => point.elevation))
  // );

  // const maxTerrainElevation = Math.round(
  //   Math.max(...terrain.map(point => point.elevation))
  // );

  const minRelativeElevation = Math.ceil(
    Math.min(...possibleRouteFlattened.map(point => point.elevation.relative))
  );

  const maxRelativeElevation = Math.ceil(
    Math.max(...possibleRouteFlattened.map(point => point.elevation.relative))
  );

  // const isElevationReachable = (
  //   (maxTerrainElevation - homeElevation + missionAltitude)
  //   < (maxRelativeHeight * ((100 - safetyThresshold) / 100))
  // );

  // const isBatterySufficient = estimates.battery < (100 - safetyThresshold);

  // Update mission data containing route and terrain data
  return ({
    route: possibleRouteFlattened,
    terrain,
    totalDistance: distance,
    minRelativeElevation,
    maxRelativeElevation,
    estimates,
    forwardOverlap,
    sideOverlap,
    captureDistance: Math.floor(photoCoverageHeight
      * ((100 - forwardOverlap) / 100)), // Take photos n% sooner for better overlap
  });
}

export default calculateRoute;
