import React from 'react';
import styled, { css } from 'styled-components';

const Pulses = styled.div`
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    background-color: #111;
    z-index: -1;
    opacity: 0.9;
    animation: pulse 2000ms infinite;
  }

  &:after {
    top: 20%;
    left: 20%;
    bottom: 20%;
    right: 20%;
    animation-delay: 300ms;
  }

  @keyframes pulse {
    0% {
      transform: scale(0);
    }
    70% {
      transform: scale(7);
    }
    100% {
      opacity: 0;
      transform: scale(7);
    }
  }
`;

const Dot = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #F01F37;
  animation: push 2000ms infinite;

  @keyframes push {
    5% {
      transform: scale(0.9)
    }
  }
`;

const Ring = styled.div`
  position: absolute;
  top: -20%;
  left: -20%;
  bottom: -20%;
  right: -20%;
  border-radius: 50%;
  box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0.7);
`;

function User() {
  return (
    <Pulses>
      <Dot />
      <Ring />
    </Pulses>
  );
}

export default User;
