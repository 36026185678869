import { bbox } from '@turf/turf';

import createMarker from './createMarker';

const setupMarkers = ({
  mapRef,
  markersRef,
  propsRef,
  dragendTimeoutRef,
}) => {
  if (!mapRef.current) return;
  const geoJSON = propsRef.current?.value;
  const { features } = geoJSON;

  // if (markersRef.current.length) {
  //   markersRef.current.map(marker => marker.marker.remove());
  //   markersRef.current = [];
  //   updatePath(markersRef.current);
  //   updateRoute(markersRef.current);
  // }

  features.map((point, index) => {
    if (
      (point.properties.type === 'home' && index === 0)
      || point.properties.type === 'user'
    ) {
      createMarker({
        mapRef,
        coordinates: point.geometry.coordinates,
        home: point.properties.type === 'home',
        propsRef,
        markersRef,
        dragendTimeoutRef,
      });
    }

    return null;
  });

  mapRef.current.fitBounds(bbox(geoJSON), { padding: 100, maxZoom: 15 });
};

export default setupMarkers;
