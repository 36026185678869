import { length } from '@turf/turf';
import { lineString } from '@turf/helpers';

function getHoverInfo({
  event,
  mapRef,
  propsRef,
  markersRef,
}) {
  const lastPointCoordinates = markersRef.current?.at(-1)?.coordinates;
  const hoveredCoordinates = [event.lngLat.lng, event.lngLat.lat];

  const elevationAtHover = mapRef.current.queryTerrainElevation(
    hoveredCoordinates, { exaggerated: false }
  );

  // Get distance from last user point to hover point
  let distanceAtHover;
  if (lastPointCoordinates) {
    distanceAtHover = length(
      lineString([
        lastPointCoordinates,
        hoveredCoordinates,
      ]), { units: 'meters' }
    );
  } else {
    distanceAtHover = 0;
  }

  propsRef.current.onHover && propsRef.current.onHover({
    coordinates: hoveredCoordinates,
    elevation: Math.round(elevationAtHover),
    distance: Math.round(distanceAtHover),
  });
}

export default getHoverInfo;
