/* eslint-disable */
function simplifyPath(points, tolerance) {
  // HELPER FUNCTIONS
  const Vector = function (x, y) {
    this.x = x;
    this.y = y;
  };
  const Line = function (p1, p2) {
    this.p1 = p1;
    this.p2 = p2;

    this.distanceToPoint = function (point) {
      // Slope
      const m = (this.p2.y - this.p1.y) / (this.p2.x - this.p1.x);

      // Y offset
      const b = this.p1.y - m * this.p1.x;
      const d = [];

      // Distance to the linear equation
      d.push(Math.abs(point.y - m * point.x - b) / Math.sqrt(m ** 2 + 1));

      // Distance to p1
      d.push(
        Math.sqrt((point.x - this.p1.x) ** 2 + (point.y - this.p1.y) ** 2)
      );

      // Distance to p2
      d.push(
        Math.sqrt((point.x - this.p2.x) ** 2 + (point.y - this.p2.y) ** 2)
      );

      // Return the smallest distance
      return d.sort(
        (a, b) => a - b // causes an array to be sorted numerically and ascending
      )[0];
    };
  };

  // MAIN FUNCTION
  const douglasPeucker = function (points, tolerance) {
    let returnPoints = [];
    if (points.length <= 2) {
      return [points[0]];
    }

    // Make line from start to end
    const line = new Line(points[0], points[points.length - 1]);

    // Find the largest distance from intermediate poitns to this line
    let maxDistance = 0;
    let maxDistanceIndex = 0;
    for (let i = 1; i <= points.length - 2; i++) {
      const distance = line.distanceToPoint(points[i]);
      if (distance > maxDistance) {
        maxDistance = distance;
        maxDistanceIndex = i;
      }
    }
    // Check if the max distance is greater than our tollerance allows
    if (maxDistance >= tolerance) {
      var p = points[maxDistanceIndex];
      line.distanceToPoint(p, true);

      // Include this point in the output
      returnPoints = returnPoints.concat(
        douglasPeucker(points.slice(0, maxDistanceIndex + 1), tolerance)
      );

      // ReturnPoints.push( points[maxDistanceIndex] );
      returnPoints = returnPoints.concat(
        douglasPeucker(points.slice(maxDistanceIndex, points.length), tolerance)
      );
    } else {
      // Ditching this point
      var p = points[maxDistanceIndex];
      line.distanceToPoint(p, true);
      returnPoints = [points[0]];
    }
    return returnPoints;
  };
  const arr = douglasPeucker(points, tolerance);

  // Always have to push the very last point on so it doesn't get left off
  arr.push(points[points.length - 1]);
  return arr;
}

export default simplifyPath;
