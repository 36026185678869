import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import ReactDOM from 'react-dom';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import updatePath from './functions/updatePath';
import updateRoute from './functions/updateRoute';
import getHoverInfo from './functions/getHoverInfo';
import setupMarkers from './functions/setupMarkers';
import createMarker from './functions/createMarker';

import User from './User';

import DRONES from './data/drones';

const Map = forwardRef(({
  value,
  onHover,
  onUpdate,
  onChange,
  onMarkerClick,
  onGeoLocate,
  ...props
}, ref) => {
  mapboxgl.accessToken = props.accessToken;

  const propsRef = useRef({
    value,
    onHover,
    onUpdate,
    onChange,
    onMarkerClick,
    onGeoLocate,
    ...props,
  });

  useEffect(() => {
    propsRef.current = {
      value,
      onHover,
      onUpdate,
      onChange,
      onMarkerClick,
      onGeoLocate,
      ...props,
    };
  }, [
    value,
    onHover,
    onUpdate,
    onChange,
    onMarkerClick,
    onGeoLocate,
    props,
  ]);

  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const markersRef = useRef([]);
  const dragendTimeoutRef = useRef(0);
  const geoLocateControlRef = useRef(null);
  const userMarkerRef = useRef(null);

  // MAP INITIALIZATION
  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/thehive-rs/cl51nok4e003s14momwkrejo0',
      // style: 'mapbox://styles/thehive-rs/cl5ad6srb000p14nchwpcsfds',
      pitch: 0,
      bearing: 0,
      // center: propsRef.current?.value?.features
      //   ? propsRef.current.value.features[0].geometry.coordinates
      //   : propsRef.current.homeCoordinates
      //     ? propsRef.current.homeCoordinates
      //     : propsRef.current.center,
      center: propsRef.current.center,
      zoom: propsRef.current.zoom,
      cooperativeGestures: true,
      attributionControl: false,
      projection: 'globe',
    });

    return () => mapRef.current.remove();
  }, []);

  // MAP SETUP
  useEffect(() => {
    mapRef.current.on('load', () => {
      // Create source used for line drawing
      mapRef.current.addSource('mission-path', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      // Add polygon (for mission coverage)
      mapRef.current.addLayer({
        id: 'poly',
        type: 'fill',
        source: 'mission-path',
        // layout: {
        //   'line-cap': 'round',
        //   'line-join': 'round',
        // },
        paint: {
          'fill-color': '#111',
          'fill-opacity': 0.5,
        },
        filter: ['==', 'type', 'MISSION_COVERAGE'],
      });

      // Add solid line (between user defined points)
      mapRef.current.addLayer({
        id: 'solid',
        type: 'line',
        source: 'mission-path',
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#fff',
          'line-width': 2,
          'line-opacity': 1,
        },
        filter: ['==', 'type', 'MISSION_PATH'],
      });

      // Add dashed line (from and to home point)
      mapRef.current.addLayer({
        id: 'dashed',
        type: 'line',
        source: 'mission-path',
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#fff',
          'line-width': 2,
          'line-opacity': 1,
          'line-dasharray': [3, 5],
        },
        filter: ['==', 'type', 'HOME_PATH'],
      });

      mapRef.current.addSource('mission-points', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      // Add terrain follow subpoints
      mapRef.current.addLayer({
        id: 'points',
        type: 'circle',
        source: 'mission-points',
        layout: {
          visibility: 'visible',
        },
        paint: {
          'circle-radius': 3,
          'circle-color': '#F01F37',
          'circle-opacity': 1,
        },
      });

      if (!propsRef.current?.value?.features
        && propsRef.current.homeCoordinates) {
        createMarker({
          mapRef,
          coordinates: propsRef.current.homeCoordinates,
          home: true,
          markersRef,
          propsRef,
          dragendTimeoutRef,
        });

        mapRef.current.flyTo({
          center: propsRef.current.homeCoordinates,
          zoom: propsRef.current.zoom + 2,
        });
      }

      geoLocateControlRef.current = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: false,
        showUserLocation: false,
        showUserHeading: false,
      });

      mapRef.current.addControl(geoLocateControlRef.current);

      geoLocateControlRef.current.on('geolocate', (event) => {
        propsRef.current.onGeoLocate('located', event);

        const userMarkerNode = document.createElement('user');

        userMarkerRef.current = new mapboxgl.Marker(userMarkerNode, {
          pitchAlignment: 'map',
        }).setLngLat([event.coords.longitude, event.coords.latitude])
          .addTo(mapRef.current);

        ReactDOM.render((
          <User />
        ), userMarkerNode);
      });

      // mapRef.current?.style?.stylesheet?.layers.forEach((layer) => {
      //   if (layer.type === 'symbol') {
      //     mapRef.current.setLayoutProperty(layer.id, 'visibility', 'none');
      //   }
      // });
    });

    return () => {
      mapRef.current.off('load');
      geoLocateControlRef?.current?.off('geolocate');
    };
  }, []);

  // LOAD
  useEffect(() => {
    let initialTerrainDataLoaded = false;
    let interval;

    if (!initialTerrainDataLoaded) {
      interval = setInterval(() => {
        if (!mapRef.current) return;

        if (propsRef.current && mapRef.current._loaded
          && mapRef.current.queryTerrainElevation(propsRef.current.center)
        ) {
          initialTerrainDataLoaded = true;
          clearInterval(interval);
          if (propsRef.current?.value?.features) {
            setupMarkers({
              mapRef,
              propsRef,
              markersRef,
              dragendTimeoutRef,
            });
          }
        }
      }, 10);
    }

    return () => clearInterval(interval);
  }, []);

  // TODO: Try moving this to the interval above
  // TERRAIN UPDATE
  useEffect(() => {
    let terrainDataLoaded = false;

    const debounced = debounce(() => {
      if (!mapRef.current) return;
      if (!mapRef.current._loaded) return;
      updateRoute({
        mapRef,
        markersRef,
        propsRef,
      });

      terrainDataLoaded = true;
    }, 1000);

    mapRef.current.on('sourcedata', (event) => {
      if (event.source.url === 'mapbox://mapbox.mapbox-terrain-dem-v1'
      && !terrainDataLoaded) {
        // eslint-disable-next-line max-len
        debounced();
      }
    });

    return () => {
      mapRef.current.off('sourcedata');
      debounced.cancel();
    };
  }, []);

  // PROP UPDATE
  useEffect(() => {
    if (!mapRef.current) return;
    if (!mapRef.current._loaded) return;
    updatePath({ mapRef, markersRef, propsRef });
    updateRoute({ mapRef, markersRef, propsRef });
  }, [
    props.drone,
    props.center,
    props.zoom,
    // props.homeCoordinates, // TODO: check why this is causing a re-render
    props.addedHomeAltitude,
    props.elevationPrecision,
    props.elevationSimplificationFactor,
    props.missionPitch,
    props.missionAltitude,
    props.missionSpeed,
    props.useTerrainElevation,
    props.autoFollowTerrain,
    props.maxWaypointDistance,
    props.maxRelativeHeight,
    props.safetyThresshold,
  ]);

  // HOVER
  useEffect(() => {
    mapRef.current.on('mousemove', (event) => getHoverInfo({
      event,
      mapRef,
      markersRef,
      propsRef,
    }));

    return () => mapRef.current.off('mousemove');
  }, []);

  // CLICK
  useEffect(() => {
    mapRef.current.on('click', event => {
      createMarker({
        mapRef,
        coordinates: [event.lngLat.lng, event.lngLat.lat],
        home: markersRef.current.length === 0,
        markersRef,
        propsRef,
        dragendTimeoutRef,
      });
    });

    return () => mapRef.current.off('click');
  }, []);

  // RIGHT CLICK
  useEffect(() => {
    mapRef.current.on('contextmenu', event => {
      if (!navigator?.clipboard) return;
      navigator.clipboard.writeText(`${event.lngLat.lng}, ${event.lngLat.lat}`);
    });

    return () => mapRef.current.off('contextmenu');
  }, []);

  // IMPERATIVE METHODS
  useImperativeHandle(ref, () => ({
    geoLocate() {
      propsRef.current.onGeoLocate('started');
      geoLocateControlRef.current.trigger();
    },
    removeUserMarker() {
      userMarkerRef.current.remove();
    },
    removeMarker(marker) {
      marker.marker.remove();
      markersRef.current = markersRef.current.filter(m => m.id !== marker.id);
      updatePath({ mapRef, propsRef, markersRef });
      updateRoute({ mapRef, propsRef, markersRef });
    },
    showLabels() {
      mapRef.current.style.stylesheet.layers.forEach((layer) => {
        if (layer.type === 'symbol') {
          mapRef.current.setLayoutProperty(layer.id, 'visibility', 'visible');
        }
      });
    },
    hideLabels() {
      mapRef.current.style.stylesheet.layers.forEach((layer) => {
        if (layer.type === 'symbol') {
          mapRef.current.setLayoutProperty(layer.id, 'visibility', 'none');
        }
      });
    },
  }));

  return (
    <div
      ref={mapContainerRef}
      className="map-container"
      style={{ width: '100%', height: '100%' }}
    />
  );
});

Map.defaultProps = {
  drone: 'Mavic Pro',
  zoom: 10,
  center: [18.600745827365422, 42.45309480289012],
  // center: [20.44796446917414, 44.82733522163534],
  homeCoordinates: false,
  addedHomeAltitude: 0,
  missionPitch: 90,
  missionAltitude: 100,
  missionSpeed: 7,
  useTerrainElevation: true,
  elevationPrecision: 10,
  elevationSimplificationFactor: 10,
  autoFollowTerrain: true,
  maxWaypointDistance: 2000,
  maxRelativeElevation: 500,
  minRelativeElevation: -200,
  forwardOverlap: 40,
  sideOverlap: 20,
  value: {},
  onHover: null,
  onUpdate: null,
  onChange: null,
  onMarkerClick: null,
};

Map.propTypes = {
  drone: PropTypes.oneOf(DRONES.map(drone => drone.name)),
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  homeCoordinates: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
  ]),
  addedHomeAltitude: PropTypes.number,
  missionPitch: PropTypes.number,
  missionAltitude: PropTypes.number,
  missionSpeed: PropTypes.number,
  useTerrainElevation: PropTypes.bool,
  elevationPrecision: PropTypes.number,
  elevationSimplificationFactor: PropTypes.number,
  autoFollowTerrain: PropTypes.bool,
  maxWaypointDistance: PropTypes.number,
  maxRelativeElevation: PropTypes.number,
  minRelativeElevation: PropTypes.number,
  forwardOverlap: PropTypes.number,
  sideOverlap: PropTypes.number,
  value: PropTypes.objectOf(PropTypes.arrayOf),
  onHover: PropTypes.func,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
  onMarkerClick: PropTypes.func,
};

export default Map;
