import DRONES from '../data/drones';

function getDroneInfo({
  propsRef,
}) {
  const DRONE = DRONES.find(i => i.name === propsRef?.current.drone);

  const deg2rad = Math.PI / 180;
  const rad2deg = 180 / Math.PI;

  const getHorizontalFOV = (FOV, w = 4, h = 3) => Math.atan(
    Math.tan(FOV * deg2rad / 2) * (w / Math.sqrt(w ** 2 + h ** 2))
  ) * rad2deg * 2;

  const photoCoverageWidth = Math.tan(
    getHorizontalFOV(DRONE.fov) / 2.0 * Math.PI / 180.0
  ) * 2.0 * (propsRef.current.missionAltitude || 0);
  const photoCoverageHeight = photoCoverageWidth * 3 / 4;

  const maxAscentSpeed = DRONE?.maxDescentSpeed;
  const maxDescentSpeed = DRONE?.maxAscentSpeed;

  const batteryConsumption = (100 / (
    DRONE.maxFlyTime.measuredAtSpeed
    * DRONE.maxFlyTime.minutes * 60
  )); // percentage per meter

  const speedAdjustedBatteryConsumption = (
    propsRef.current.missionSpeed <= DRONE.maxFlyTime.measuredAtSpeed
    ? batteryConsumption * (
        DRONE.maxFlyTime.measuredAtSpeed / propsRef.current.missionSpeed
      )
    : batteryConsumption
  );

  const battery = {
    distanceConsumption: speedAdjustedBatteryConsumption,
    ascentConsumption: speedAdjustedBatteryConsumption * 3,
    descentConsumption: speedAdjustedBatteryConsumption * 1,
  };

  return {
    maxAscentSpeed,
    maxDescentSpeed,
    photoCoverageWidth,
    photoCoverageHeight,
    battery,
  };
}

export default getDroneInfo;
