export default [{
  name: 'Spark',
  maxAscentSpeed: 3,
  maxDescentSpeed: 3,
  maxSpeed: 14,
  maxFlyTime: {
    minutes: 16,
    measuredAtSpeed: 5.5,
  },
  fov: 81.9,
}, {
  name: 'Mavic Pro',
  maxAscentSpeed: 5,
  maxDescentSpeed: 3,
  maxSpeed: 18,
  maxFlyTime: {
    minutes: 27,
    measuredAtSpeed: 7,
  },
  fov: 78.8,
}, {
  name: 'Mavic 2 Enterprise Dual',
  maxAscentSpeed: 5,
  maxDescentSpeed: 3,
  maxSpeed: 20,
  maxFlyTime: {
    minutes: 31,
    measuredAtSpeed: 7,
  },
  // fov: 85, // regular
  fov: 57, // when using thermal camera
}];
