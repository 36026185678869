import calculateRoute from './calculateRoute';

const updateRoute = ({
  mapRef,
  propsRef,
  markersRef,
}) => {
  if (!mapRef.current) return;
  if (!mapRef.current._loaded) return;

  if (markersRef?.current?.length > 1) {
    const mission = calculateRoute({
      mapRef,
      markersRef,
      propsRef,
    });

    propsRef.current.onUpdate && propsRef.current.onUpdate(mission);

    const geoJSON = {
      type: 'FeatureCollection',
      features: mission.route.map(point => ({
        type: 'Feature',
        properties: {
          type: point.type,
          elevation: point.elevation,
        },
        geometry: {
          type: 'Point',
          coordinates: [...point.coordinates],
        },
      })),
    };

    const onChangeCallback = (
      mission.maxRelativeElevation < propsRef.current.maxRelativeElevation
      && mission.minRelativeElevation > propsRef.current.minRelativeElevation
      && mission.estimates.battery < 90
    ) ? geoJSON : null;

    propsRef.current.onChange && propsRef.current.onChange(onChangeCallback);
    mapRef?.current?.getSource('mission-points').setData(geoJSON);
  } else {
    propsRef?.current.onUpdate && propsRef?.current.onUpdate(null);
    propsRef?.current.onChange && propsRef?.current.onChange(null);
    mapRef?.current?.getSource('mission-points').setData({
      type: 'FeatureCollection',
      features: [],
    });
  }
};

export default updateRoute;
