import { buffer, lineString } from '@turf/turf';
import DRONES from '../data/drones';

const updatePath = ({
  mapRef,
  propsRef,
  markersRef,
}) => {
  if (!mapRef.current) return;
  if (!mapRef?.current?._loaded) return;

  // TODO: use this from gerDroneInfo function
  const DRONE = DRONES.find(i => i.name === propsRef?.current.drone);
  const deg2rad = Math.PI / 180;
  const rad2deg = 180 / Math.PI;

  const getHorizontalFOV = (FOV, w = 4, h = 3) => Math.atan(
    Math.tan(FOV * deg2rad / 2) * (w / Math.sqrt(w ** 2 + h ** 2))
  ) * rad2deg * 2;

  const coverageRadius = Math.tan(
    getHorizontalFOV(DRONE.fov) / 2.0 * Math.PI / 180.0
  ) * 2.0 * (propsRef.current.missionAltitude || 0) / 2;

  const overlapAdjustedCoverageRadius = coverageRadius
    * (100 - propsRef.current.sideOverlap) / 100;

  const EMPTY = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
    properties: {},
  };

  const homePath = markersRef?.current?.length > 1 ? lineString(
    [
      markersRef?.current.at(1).coordinates,
      markersRef?.current.at(0).coordinates,
      markersRef?.current.at(-1).coordinates,
    ]
  ) : EMPTY;

  const missionPath = markersRef?.current?.length > 2
    ? lineString(markersRef?.current
      .filter((_, i) => i !== 0)
      .map(point => point.coordinates)
    ) : EMPTY;

  const missionCoverage = markersRef?.current?.length > 2
    ? buffer(lineString(markersRef?.current
      .filter((_, i) => i !== 0)
      .map(point => point.coordinates)
    ), overlapAdjustedCoverageRadius, { units: 'meters' }) : EMPTY;

  homePath.properties.type = 'HOME_PATH';
  missionPath.properties.type = 'MISSION_PATH';
  missionCoverage.properties.type = 'MISSION_COVERAGE';

  mapRef?.current?.getSource('mission-path').setData({
    type: 'FeatureCollection',
    features: [
      homePath,
      missionPath,
      missionCoverage,
    ],
  }
  );
};

export default updatePath;
