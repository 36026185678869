import React, { useRef, forwardRef, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Info, Minimize2, Maximize2, Crosshair } from 'react-feather';

import Map from './Map';

function enterFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
}

function exitFullscreen() {
  document.exitFullscreen();
}

const GlobalStyle = createGlobalStyle`
  .mapboxgl-ctrl-geolocate {
    display: none !important;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Controls = styled.div`
  position: absolute;
  top: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ControlsRight = styled(Controls)`
  right: 0;
`;

const ControlsLeft = styled(Controls)`
  left: 0;
`;

const Button = styled.div`
  display: flex;
  padding: 8px;
  background: white;
  border-radius: 5px;
  cursor: pointer;

  ${({ status }) => status === 'loading' && css`
    & > * {
      animation: loading 500ms infinite;
    }
  `};

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(90deg);
    }
  }
`;

const App = forwardRef((props, ref) => {
  const containerRef = useRef();

  const forwardedMapRef = ref;
  const internalMapRef = useRef();
  const mapRef = forwardedMapRef || internalMapRef;

  const [showLabels, setShowLabels] = useState(true);
  const [userLocation, setUserLocation] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  // const handleOnHover = (data) => {
  //   props.onHover && props.onHover(data);
  //   // do something more
  // };

  // const handleOnUpdate = (data) => {
  //   props.onUpdate && props.onUpdate(data);
  //   // do something more
  // };

  // const handleOnChange = (data) => {
  //   props.onChange && props.onChange(data);
  //   // do something more
  // };

  // const handleOnMarkerClick = (data) => {
  //   if (props.onMarkerClick) {
  //     props.onMarkerClick(data);
  //   } else {
  //     mapRef.current.removeMarker(data);
  //     // do something more
  //   }
  // };

  const handleFullscreen = () => {
    if (fullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen(containerRef.current);
    }
    setFullscreen(!fullscreen);
  };

  const handleGeoLocate = () => {
    if (!userLocation) {
      mapRef.current.geoLocate();
    } else {
      mapRef.current.removeUserMarker();
      setUserLocation(false);
    }
  };

  const handleOnGeoLocate = (event) => {
    if (event === 'started') {
      setUserLocation('loading');
    } else if (event === 'located') {
      setUserLocation('success');
    }
  };

  const handleShowLables = () => {
    if (showLabels) {
      mapRef.current.hideLabels();
    } else {
      mapRef.current.showLabels();
    }
    setShowLabels(!showLabels);
  };

  return (
    <Container ref={containerRef}>
      <GlobalStyle />
      <Map
        ref={mapRef}
        {...props}
        // onHover={handleOnHover}
        // onChange={handleOnChange}
        // onUpdate={handleOnUpdate}
        // onMarkerClick={handleOnMarkerClick}
        onGeoLocate={handleOnGeoLocate}
      />
      <ControlsLeft>
        <Button onClick={handleShowLables}>
          <Info size={18} color={showLabels ? '#333' : '#aaa'} />
        </Button>
      </ControlsLeft>
      <ControlsRight>
        <Button onClick={handleFullscreen}>
          {fullscreen ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
        </Button>
        <Button onClick={handleGeoLocate} status={userLocation}>
          <Crosshair size={18} color={userLocation ? '#F01F37' : '#333'} />
        </Button>
      </ControlsRight>
    </Container>
  );
});

export default App;
export { default as ElevationGraph } from './ElevationGraph';
export { default as CapturesMap } from './CapturesMap';
