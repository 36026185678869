import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Home } from 'react-feather';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 100px 100px 100px 0;
  
  background: #F01F37;
  
  color: white;
  font-family: 'Avenir Next', 'Helvetica Neue', 'Helvetica', sans-serif;
  font-weight: 600;

  cursor: pointer;

  transform: translateY(-8px) rotate(-45deg);
  transition: transform 300ms;
  &:hover {
    transform: translateY(-12px) rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
    opacity: 0.8;
  }

  ${({ home }) => home && css`
    width: 30px;
    height: 30px;
  `}

  &:hover + div {
    display: block;
    opacity: 1;
  }
`;

const GlobalNumbering = createGlobalStyle`
  .mapboxgl-canvas-container {
    ${[...Array(50).keys()].map((_, index) => css`
      marker.mapboxgl-marker:nth-of-type(${index + 1}) 
      > .custom-waypoint-pin:after {
        content: '${index}';
      }
    `)}
  }
`;

function Pin({
  id,
  marker,
  markersRef,
  updateRoute,
  point,
  onClick,
  home,
  ...rest
}) {
  return (
    <>
      <GlobalNumbering />
      <Wrapper
        home={home}
        className={home ? 'custom-home-pin' : 'custom-waypoint-pin'}
        onClick={onClick}
        {...rest}
      >
        {home ? (
          <Home
            color="white"
            size={12}
            style={{ transform: 'rotate(45deg)' }}
          />
          ) : null}
      </Wrapper>
    </>
  );
}

export default Pin;
